:root {
  --black: #050505;
  --red: #EF233C;
  --white: #FFFCFF;
  --grey: #738290;
  --light-grey: #818D92
}

html {
  background-color: var(--black);
  color: var(--white);
  text-align: center;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  animation: transistionIn 1.5s;
}

@keyframes transistionIn {
  from {
    opacity: 0;
    transform: translateY(-100px);
    filter: grayscale(100%);
  }

  to {
    opacity: 100;
    transform: translateY(0px);
    filter: grayscale(0%);
  }
}

.App {
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* --------------------------------------SHARED-------------------------------------- */

#services,
#reviews,
#location,
#book-now,
#hours {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
}

.services,
.reviews,
.location,
.book-now,
.hours {
  font-size: 65px;
  text-decoration: underline;
  color: var(--red);
}

/* --------------------------------------NAVBAR-------------------------------------- */

.navbar {
  display: flex;
  font-size: 30px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 20px;
}

#title {
  display: flex;
  justify-content: center;
  color: var(--white);
  padding-left: 25px;
  font-family: 'Dancing Script', cursive;
  font-size: 71px;
  transition: .5s ease;
  text-shadow: 3px 3px#B0413E;
  transform-origin: -50%;
  text-shadow: 3px 3px#B0413E;

}



.navbar-links {
  display: flex;
  gap: 25px;
  font-size: 30px;
  transition: .25s ease;
  padding-right: 20px;
}



.navbar a {
  text-decoration: none;
  color: var(--white);
  transition: .25s ease;
}

/* ----------------------------------MAIN IMAGES--------------------------------- */

.images {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px; */
  display: flex;
  max-width: 1200px;
  margin: auto;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-top: 50px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}

.div2 {
  grid-area: 1 / 3 / 2 / 4;
}

.div3 {
  grid-area: 1 / 5 / 2 / 6;
}

.div4 {
  grid-area: 3 / 5 / 4 / 6;
}

.div5 {
  grid-area: 3 / 3 / 4 / 4;
}

.div6 {
  grid-area: 3 / 1 / 4 / 2;
}

.div7 {
  grid-area: 2 / 4 / 3 / 5;
}

.div8 {
  grid-area: 2 / 2 / 3 / 3;
}

.img1,
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8 {
  height: 350px;
  width: auto;
  box-shadow: 5px 5px #fffcff42;
  transition: .5s ease;
}

.img1:hover,
.img2:hover,
.img3:hover,
.img4:hover,
.img5:hover,
.img6:hover,
.img7:hover,
.img8:hover {
  cursor: pointer;
  transform: scale(1.3);
  transition: .5s ease;
  box-shadow: 5px 5px var(--red);
  z-index: 1;
}

footer {
  font-size: 12px;
  padding-top: 10px;
}


/* --------------------------------------SERVICES-------------------------------------- */

.booking-link {
  text-decoration: none;
  color: var(--white);
}

.services-container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
}

.service-item {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.service-item:hover {
  color: var(--red);
  cursor: pointer;
}

.service-item-name {
  font-size: 35px;
}

.service-item-price {
  font-size: 25px;
}

.name-description {
  display: flex;
  flex-direction: column;
}

.disclosure {
  font-size: 15px;
}


/* ----------------------------------REVIEWS-------------------------------- */


.reviews-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 30px;
  padding-top: 100px;
  padding-left: 10px;
  padding-right: 10px;
}

.reviews-container-item {

  display: flex;
  flex-direction: column;
}

.reviews-item-star-review {
  font-size: 25px;
  color: gold;
}

.reviews-item-purchase {
  font-size: 20px;
}

.reviews-item-name {
  font-size: 10px;
}

.reviews-item-review {
  font-size: 20px;
}

.reviews-item-date {
  font-size: 10px;
}


/* ------------------LOCATION------------------ */
.map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 100px;
}


/* --------------HOURS----------- */
.hours-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 15px;
  font-size: 25px;
  padding-bottom: 20px;
}

/* ---------------Contact------------- */
.socials {
  display: flex;
  font-size: 30px;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid var(--grey);
  border-bottom: 2px solid var(--grey);
}

.phone {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.socials a {
  color: var(--white);
  text-decoration: none;
}

.phone,
.insta {
  transition: .5s ease;
}
/* -------------------MAP---------------------- */

.address-map {
  padding-top: 60px;
  font-size: 35px;
  text-decoration: none;
  color: var(--white);
}


/* -------------------MOBILE---------------------- */

@media only screen and (max-width: 750px) {
  body {
    overflow-x: hidden;
  }

  #navbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  #title {
    font-size: 30px;
    padding-left: 5px;
  }

  .navbar {
    align-items: center;
    margin-bottom: 20px;
  }

  .navbar-links {
    font-size: 25px;
  }

  .images {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .img1,
  .img2,
  .img3,
  .img4,
  .img5,
  .img6,
  .img7,
  .img8 {
    height: 125px;
    width: auto;
  }

  .services-container {
    max-width: 90%;
    margin: auto;
    gap: 10px;
    padding-top: 20px;
  }

  .service-item {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  #reviews {
    padding-top: 10px;
  }

  .reviews-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 5px;
  }

  .map {
    height: 300px;
    width: auto;
    margin-top: 20px;
  }

  #services,
  #reviews,
  #location,
  #book-now,
  #hours,
  .address-map {
    padding-top: 10px;
  }





  #title {
    transform: none;
    padding-left: 20px;
  }

  .socials {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .hours-info {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


/* ---------------------------------------TABLET--------------------------------------- */
@media only screen and (max-width: 1000px) {
  .services-nav,
  .reviews-nav,
  .location-nav,
  .hours-nav,
  .insta-nav {
    display: none;
  }
  .booking-nav {
    border: 1px solid var(--white);
    padding: 5px;
    box-shadow: 5px 5px var(--red);
    text-shadow: 2px 2px var(--red);
  }
}
/* -----------------------------DESKTOP-------------------------------- */

@media only screen and (min-width: 650px) {
  #title:hover {
    transition: .5s ease;
    cursor: pointer;
  }

  .navbar a:hover {
    cursor: pointer;
    font-size: 45px;
    transition: .25s ease;
    color: var(--red);
  }

  .insta:hover,
  .phone:hover {
    cursor: pointer;
    font-size: 40px;
    transition: .5s ease;
  }
}